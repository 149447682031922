import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError} from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {trackError} from "../../../../tracker";

@Injectable({ providedIn: "root" })
export class HttpService {
  constructor(private _http: HttpClient, private router: Router) {}

  SERVER_URL = environment.apiUrl;

  get<R>(path: string) {
    let headers = new HttpHeaders();
    headers.set("Accept", "application/json");
    return this._http.get<R>(path, {headers}).pipe(
      map(r => r as R),
      catchError((err) => {
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE === 'INVALID_TOKEN_ERROR') {
          // Handle specific error case
        }
        trackFetchError('GET', path, err)
        return throwError(err);
      })
    );
  }

  post<R>(path: string, body: unknown|undefined): Observable<R> {
    let headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return this._http.post(path, body, {headers}).pipe(
      map(r => r as R),
      catchError((err) => {
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
        }
        trackFetchError('POST', path, err, body)
        return throwError(err);
      })
    )
  }

  put<R>(path: string, body: unknown|undefined) {
    let headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return this._http.put(path, body, {headers}).pipe(
      map(r => r as R),
      catchError((err) => {
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
        }
        trackFetchError('PUT', path, err, body)
        throw 'Error making an API request' + err; // Throw err.
      })
    )
  }

  delete<R>(path: string, body: unknown|undefined) {
    let headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return this._http.delete(path, {headers, body}).pipe(
      map(r => r as R),
      catchError((err) => {
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
        }
        trackFetchError('DELETE', path, err, body)
        throw 'Error making an API request' + err; // Throw err.
      })
    )
  }

  postWithBlobResponse<R>(path: string, body: any){
    let headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return this._http.post(path, body,  { headers, responseType: 'blob', observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => response.body as Blob),
      catchError((err) => {
        if (err.error && err.error.error && err.error.error.ERROR_CODE && err.error.error.ERROR_CODE == 'INVALID_TOKEN_ERROR') { // Cond for when request is made with a bad token.
        }
        trackFetchError('POST', path, err, body)
        return throwError(err);
      })
    )
  }
}

function trackFetchError(method: string, path: string,  err: any, bodyData: any = null) {
  let body = bodyData
  if (bodyData instanceof FormData) {
    let data: any = {}
    bodyData.forEach((v, k) => data[k] = v)
    body = data
  }
  trackError({
    msg: `unable to ${method} ${path}`,
    data: {
      status: err.status,
      url: err.url,
      message: err.message,
      error: err.error,
      body
    }
  })
}

