import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthenticationService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.authService.checkAuthentication()) {
      return true;
    } else {
      return this.authService.getUser().pipe(
        map(user => {
          if (user) {
            this.authService.setAuthentication(true);
            return true;
          } else {
            this.router.navigate(['/account/admin-login'], { queryParams: { returnUrl: state.url } });
            return false;
          }
        }),
        catchError(() => {
          this.router.navigate(['/account/admin-login'], { queryParams: { returnUrl: state.url } });
          return of(false);
        })
      );
    }
  }
}
