<div class="row">
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row mb-2">
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-success btn-rounded mb-2 me-2" (click)="openAddEditModal(modal)">
              <i class="mdi mdi-plus mr-1"></i> {{ addLabel }}
            </button>
          </div>
        </div>
        <div class="text-center" *ngIf="loading">
          <div class="spinner-border text-primary m-1" role="status">
            <span class="sr-only">{{'LOCALIZE.LOADING' | translate}}</span>
          </div>
        </div>
        <div class="table-responsive" *ngIf="!loading">
          <table class="table align-middle table-nowrap table-hover">
            <thead class="table-info">
              <tr>
                <th *ngFor="let column of columns" scope="col">{{ column }}</th>
                <th scope="col">{{'LOCALIZE.ACTIONS' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of data | paginate: { itemsPerPage: pageLimit, currentPage: current_page, totalItems: total_count_paginator }">
                <td *ngFor="let field of fields">
                  <ng-container *ngIf="isDateField(field)">
                    {{ item[field.name] | date:'dd.MM.yyyy' }}
                  </ng-container>
                  <ng-container *ngIf="!isDateField(field)">
                    {{ item[field.name] }}
                  </ng-container>
                </td>
                <td>
                  <div>
                    <a style="color: #343A40;" class="d-inline-block" (click)="openAddEditModal(modal, item)">
                      <i class="fas fa-edit"></i>
                    </a> |
                    <a style="color: #343A40; cursor: pointer;" class="d-inline-block" (click)="deleteItem(item.id)">
                      <i class="fas fa-trash"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center mt-3" *ngIf="!loading && data?.length > 0">
          <pagination-controls (pageChange)="current_page = $event; getAllData()"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<ng-template #modal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{ modalLabel }} {{ entityName }}</h5>
    <button type="button" class="btn-close" (click)="modal.dismiss('Cross click'); resetForm()"></button>
  </div>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <form (ngSubmit)="validSubmit()" [formGroup]="validationForm">
          <div class="row">
            <ng-container *ngFor="let field of fields">
              <div class="mb-3" *ngIf="field.editable">
                <label [for]="field.name">{{ field.label }}</label>
                <input type="text" class="form-control" [id]="field.name" [formControlName]="field.name"
                  [placeholder]="field.label"
                  [ngClass]="{ 'is-invalid': submit && validationForm.controls[field.name].errors }">
                <div *ngIf="submit && validationForm.controls[field.name].errors" class="invalid-feedback">
                  <span *ngIf="validationForm.controls[field.name].errors.required">{{'LOCALIZE.THISFIELDREQUIRED' | translate}}</span>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-outline-danger"
              (click)="modal.dismiss('Cross click'); resetForm()">{{'LOCALIZE.CANCEL' | translate}}</button>
            <button class="btn btn-primary" type="submit" [disabled]="buttonLoader">
              <div *ngIf="buttonLoader">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{'LOCALIZE.LOADING' | translate}}
              </div>
              <span *ngIf="!buttonLoader"> {{'LOCALIZE.SUBMIT' | translate}} </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
