import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export default class SharedService {
    coachee_id;
    coachee_email_id;
    program_id;

    constructor(private toastr: ToastrService) { }

    handleError(error: any): void {
      if (error.error?.errors) {
        for (const key in error.error.errors) {
          if (error.error.errors.hasOwnProperty(key)) {
            error.error.errors[key].forEach((errorMessage: string) => {
              this.toastr.error(errorMessage);
            });
          }
        }
      } else if (error?.error_message) {
        const errorMessages = error.error_message;
        if (typeof errorMessages === 'string') {
          this.toastr.error(errorMessages);
        } else {
          for (const key in errorMessages) {
            if (errorMessages.hasOwnProperty(key)) {
              errorMessages[key].forEach((errorMessage: string) => {
                this.toastr.error(errorMessage);
              });
            }
          }
        }
      } else if (error?.error.error) {
        if (error.error.error.message) {
          this.toastr.error(error.error.error.message);
        } else {
          this.toastr.error(error.error.error || "An unknown error occurred.");
        }
      } else if (error?.message) {
        this.toastr.error(error.message);
      } else if (error?.error) {
        this.toastr.error(error.error);
      } else {
        this.toastr.error("An unknown error occurred.");
      }
    }

}
