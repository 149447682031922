import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '../dtos/user.dto';
import { catchError, map } from 'rxjs/operators';
import { HttpService } from './super_admin_services/http.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
  private isAuthenticated = false;
  private userInfo: User | null = null;
  private userInfoSubject = new BehaviorSubject<User | null>(null);
  private challengeToken: string;

  constructor(
    private _httpService: HttpService
  ) { }

  getUser(): Observable<User> {
    if (this.userInfo) {
      return of(this.userInfo);
    } else {
      return this._httpService.get<User>('/api/v2/me').pipe(
        map((response: User) => {
          this.userInfo = response;
          this.userInfoSubject.next(response);
          return response;
        }),
        catchError(error => {
          this.userInfoSubject.next(null);
          throw error;
        })
      );
    }
  }

  setAuthentication(status: boolean) {
    this.isAuthenticated = status;
  }

  checkAuthentication() {
    return this.isAuthenticated;
  }

  setChallengeToken(token: string) {
    this.challengeToken = token;
  }

  getChallengeToken(): string {
    return this.challengeToken;
  }

  clearChallengeToken() {
    this.challengeToken = null;
  }


  getUserInfo(): Observable<User | null> {
    return this.userInfoSubject.asObservable();
  }

}

