<header id="page-topbar">
  <div class="navbar-header ">
    <div class="d-flex">
      <div class="navbar-brand-box" style="text-align: initial;">
        <span *ngIf="isMenuOpen" class="logo logo-light">{{'LOCALIZE.MENU' | translate}}</span>
      </div>
      <button type="button" class="btn btn-sm px-3 font-size-18 header-item" id="vertical-menu-btn"
           (click)="toggleMobileMenu($event)">
           <i class="fa fa-fw fa-bars"></i>
       </button>
      <div class="sparrks_logo">
        <img src="assets/images/sparrks-logos/Sparrks-logo-rect.png" alt="" class="" height="auto" >
      </div>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ms-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">{{'LOCALIZE.ENGLISH' | translate}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
        </div>
      </div>
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <span class="d-none d-xl-inline-block ms-1" style="margin-right: 3px;">{{admin_userDetails.admin_first_name}}</span>
          <span (click)="fetchSuperAdminProfileDetails(admin_userDetails.admin_user_id);largeModal(largeDataModal)">
            <img class="rounded-circle header-profile-user" [src]="fetched_profile_image_path && gcs_pre_path_edit ? gcs_pre_path_edit+'/'+fetched_profile_image_path: 'assets/images/sparrks-logos/default_avatar.png'" alt="logo"  width="100" data-holder-rendered="true"> 
          </span>
        </button>
      </div>
      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" >
          <i class="bx bx-log-out font-size-20 align-middle me-1 text-danger logout-icon" title="logout" (click)="logout()"></i>
        </button>
      </div> 

    </div>
  </div>
</header>

  <!-- Large Modal -->
  <ng-template #largeDataModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">{{'LOCALIZE.PROFILEDETAILS' | translate}}</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click');onDismissModal();"></button>
    </div>
  
    <div class="container p-3">
      <form class="needs-validation" (ngSubmit)="validSubmitForm()" [formGroup]="validationform">
        <div class="row justify-content-center mb-3">
          
            <div class="row mb-3 ">
              <div style="display: flex;justify-content: center;">
                  <span *ngIf="gcs_pre_path">
                    <img class="rounded-circle avatar-xl" [src]="admin_userDetails.profile_image_path && gcs_pre_path ? gcs_pre_path+'/'+admin_userDetails.profile_image_path : 'assets/images/sparrks-logos/default_avatar.png'" alt="admin-profile-image" style="border-radius:50%" width="100" data-holder-rendered="true"> 
                  </span>
                  <span *ngIf="gcs_pre_path==null">
                    <img class="rounded-circle avatar-xl" [src]="admin_userDetails.profile_image_path ? admin_userDetails.profile_image_path : 'assets/images/sparrks-logos/default_avatar.png'" alt="admin-profile-image" style="border-radius:50%" width="100" data-holder-rendered="true"> 
                  </span>
                </div>
            </div>
            <div class="row justify-content-center">
              <button style="max-width:180px" type="button" class="btn btn-outline-secondary" (click)="changeProfileImage()">
                {{'LOCALIZE.CHANGEPROFILEIMAGE' | translate}}
              </button>
            </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="validationCustom01">{{'LOCALIZE.FIRSTNAME' | translate}}</label>
              <input type="text" class="form-control" id="validationCustom01" placeholder="{{'LOCALIZE.FIRSTNAME' | translate}}" formControlName="first_name"
              [ngClass]="{'is-invalid': submit && form.first_name.errors}">
              <div *ngIf="submit && form.first_name.errors" class="invalid-feedback">
              <span *ngIf="form.first_name.errors.required">{{'LOCALIZE.THISFIELDREQUIRED' | translate}}</span>
            </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="validationCustom02">{{'LOCALIZE.LASTNAME' | translate}}</label>
              <input type="text" class="form-control" id="validationCustom02" placeholder="{{'LOCALIZE.LASTNAME' | translate}}" formControlName="last_name"
              [ngClass]="{'is-invalid': submit && form.last_name.errors}">
              <div *ngIf="submit && form.last_name.errors" class="invalid-feedback">
              <span *ngIf="form.last_name.errors.required">{{'LOCALIZE.THISFIELDREQUIRED' | translate}}</span>
            </div>
            </div>
          </div>
        </div>
        <div>
          <button class="btn btn-primary" type="submit" [disabled]="buttonLoader" style="float:right">
            <div *ngIf="buttonLoader">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{'LOCALIZE.LOADING' | translate}}
            </div>
            <span *ngIf="!buttonLoader">
              {{'LOCALIZE.SUBMIT' | translate}}
            </span>
          </button>
        </div>
      </form>
    </div>
  </ng-template>