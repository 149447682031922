export type ErrorReport = {
  msg: string
  event?: string|Event
  source?: string
  lineno?: number
  colno?: number
  error?: Error
  data?: any
}

export function trackError(r: ErrorReport) {
  fetch('/api/v2/ter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      msg: r.msg,
      ev: r.event?.toString(),
      src: r.source,
      lineno: r.lineno,
      colno: r.colno,
      err: r.error,
      d: r.data
    })
  })
}
