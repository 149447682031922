export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },

  apiUrl : "https://eb37ad.sparrks.io/api/",
  adminForgotPaswordMailUrl : "https://fe0bc0.sparrks.io/account",
  coach_schedule_form_link : "https://b52e8c.sparrks.io/account/coach-timeslot-confirmation-page/",
  coachee_setup_link : "https://b52e8c.sparrks.io/account/coachee-set-password/",
  coach_setup_link:"https://b52e8c.sparrks.io/account/coach-set-password/"
};
