import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'LOCALIZE.DASHBOARD',
    icon: 'bx-home-circle',
    link: '/admin-pages/dashboard',
  },
  {
    id: 2,
    label: 'LOCALIZE.COMPANIES',
    icon: 'bx-briefcase-alt-2',
    link: '/admin-pages/companies',
  },
  {
    id: 3,
    label: 'LOCALIZE.PROGRAMS',
    icon: 'bx-receipt',
    link: '/admin-pages/programs',

  },
  {
    id: 4,
    label: 'LOCALIZE.TOPICS',
    icon: 'bx-receipt',
    link: '/admin-pages/topics',


  },
  {
    id: 4,
    label: 'LOCALIZE.SESSIONS',
    icon: 'bx-receipt',
    link: '/admin-pages/sessions',

  },
  {
    id: 5,
    label: 'LOCALIZE.COACHES',
    icon: 'bx-receipt',
    link: '/admin-pages/coaches',

  },
  {
    id: 6,
    label: 'LOCALIZE.LINK',
    icon: 'bx-receipt',
    link: '/admin-pages/video-call-links',

  },
  {
    id: 7,
    label: 'LOCALIZE.ADMINISTRATION',
    icon: 'bx-receipt',
    link: '/admin-pages/administration',
  }
];

