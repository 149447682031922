import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AdminEntity } from 'src/app/core/dtos/admin-entity';
import SharedService from 'src/app/core/services/super_admin_services/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shared-table',
  templateUrl: './shared-table.component.html',
  styleUrls: ['./shared-table.component.scss']
})
export class SharedTableComponent implements OnInit {
  @Input() title: string;
  @Input() addLabel: string;
  @Input() columns: Array<string>;
  @Input() entityName: string;
  @Input() fields: Array<{ name: string, label: string, type?: string, editable?: boolean }>;
  @Input() getAllMethod: () => Observable<any[]>;
  @Input() createMethod: (item: any) => Observable<any>;
  @Input() updateMethod: (id: number, item: any) => Observable<any>;
  @Input() deleteMethod: (id: number) => Observable<any>;

  loading: boolean = false;
  data: any[];
  current_page: number = 1;
  pageLimit: number = 10;
  total_count_paginator: number;
  modalLabel: string;
  submit: boolean = false;
  buttonLoader: boolean = false;
  validationForm: FormGroup;
  itemId: number;

  constructor(
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private _sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    if (this.fields && this.fields.length > 0) {
      this.getAllData();
      this.initForm();
    }
  }

  getAllData() {
    this.loading = true;
    this.getAllMethod().subscribe(
      (data: AdminEntity[]) => {
        this.data = data;
        this.total_count_paginator = data.length;
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
        this._sharedService.handleError(error);
      }
    );
  }

  initForm() {
    const formGroup = {};
    this.fields.forEach(field => {
      if (field.editable) {
        formGroup[field.name] = ['', Validators.required];
      }
    });
    this.validationForm = this.formBuilder.group(formGroup);
  }

  openAddEditModal(modal: any, item?: any) {
    this.modalLabel = item ? 'Edit' : 'Add';
    this.itemId = item ? item.id : null;
    if (item) {
      const editableItem = {};
      this.fields.forEach(field => {
        if (field.editable) {
          editableItem[field.name] = item[field.name];
        }
      });
      this.validationForm.patchValue(editableItem);
    }
    this.modalService.open(modal, { size: 'md', centered: true });
  }

  validSubmit() {
    this.submit = true;
    if (this.validationForm.invalid) {
      return;
    }

    const itemDetails = this.validationForm.value;
    this.buttonLoader = true;

    const action = this.itemId
      ? this.updateMethod(this.itemId, itemDetails)
      : this.createMethod(itemDetails);

    action.subscribe(
      () => {
        this.translate.get('LOCALIZE.SUCCESS_SAVE_MESSAGE', { entityName: this.entityName }).subscribe((translatedMessage: string) => {
          this.toastr.success(translatedMessage);
        });
        this.getAllData();
        this.modalService.dismissAll();
        this.resetForm();
        this.buttonLoader = false;
      },
      error => {
        console.error('Save error', error);
        this.buttonLoader = false;
        this._sharedService.handleError(error);
      }
    );
  }

  deleteItem(id: number): void {
    this.translate.get([
      'LOCALIZE.CONFIRM_DELETE_TITLE',
      'LOCALIZE.CONFIRM_DELETE_TEXT',
      'LOCALIZE.CONFIRM_BUTTON_TEXT',
      'LOCALIZE.CANCEL_BUTTON_TEXT',
      'LOCALIZE.SUCCESS_DELETE_MESSAGE',
      'LOCALIZE.DELETE_SUCCESS_TITLE',
      'LOCALIZE.DELETE_SUCCESS_TEXT',
      'LOCALIZE.ERROR_DELETE_MESSAGE'
    ]).subscribe(translations => {
      Swal.fire({
        title: translations['LOCALIZE.CONFIRM_DELETE_TITLE'],
        text: translations['LOCALIZE.CONFIRM_DELETE_TEXT'].replace('{{entityName}}', this.entityName),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: translations['LOCALIZE.CONFIRM_BUTTON_TEXT'],
        cancelButtonText: translations['LOCALIZE.CANCEL_BUTTON_TEXT']
      }).then(result => {
        if (result.isConfirmed) {
          this.deleteMethod(id).subscribe(
            () => {
              this.translate.get('LOCALIZE.DELETE_MESSAGE', { entityName: this.entityName }).subscribe(translatedMessage => {
                this.toastr.success(translatedMessage);
              });
              this.getAllData();
            },
            error => {
              console.error('Delete error', error);
              // this._sharedService.handleError(error);
              this.translate.get('LOCALIZE.ERROR_DELETE_MESSAGE', { entityName: this.entityName }).subscribe(translatedMessage => {
                this.toastr.error(translatedMessage);
              });
            }
          );
        }
      });
    });
  }


  isDateField(field: any): boolean {
    return field.type === 'date';
  }

  resetForm() {
    this.validationForm.reset();
    this.submit = false;
  }
}

