import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SuperAdminService } from "src/app/core/services/super_admin_services/super-admin.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { AuthenticationService } from "src/app/core/services/auth.service";
import SharedService from "src/app/core/services/super_admin_services/shared.service";
import { SuperAdminInfo } from "src/app/core/dtos/admin-info.dto";
import { ApiResponse } from "src/app/core/dtos/response.dto";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  rememberMe: boolean;
  validationform: FormGroup;
  imgUrl: string | ArrayBuffer;
  submit: boolean;
  admin_userDetails: any = {
    admin_user_id: "",
    admin_first_name: "",
    admin_last_name: "",
    admin_email_id: "",
    profile_image_path: "",
  };
  adminProfileImageRaw: File;
  buttonLoader: boolean = false;
  gcs_pre_path: any;
  gcs_pre_path_edit: any;
  fetched_profile_image_path: any;
  language: string;
  areYouSure: string;
  wantToLogout: string;
  yesLogout: string;
  cancelText: string;
  isMenuOpen: boolean = true;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private modalService: NgbModal,
    private _adminService: SuperAdminService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private _sharedService: SharedService,
    private authService: AuthenticationService
  ) {}

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
  ];

  openMobileMenu: boolean;
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.validationform = this.formBuilder.group({
      first_name: ["", [Validators.required]],
      last_name: ["", [Validators.required]],
    });

    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    if (this._cookiesService.check("lang")) {
      let browserLang = this._cookiesService.get("lang");
      if (browserLang == "en") {
        this.language = "english";
        this.areYouSure = "Are you sure,want to logout?";
        this.yesLogout = "Yes,logout!";
        this.cancelText = "Cancel";
      } else {
        this.language = "german";
        this.areYouSure = "Bist du dir sicher,abmelden wollen?";
        this.yesLogout = "Ja, abmelden!";
        this.cancelText = "Abbrechen";
      }
    }

    //get values stored on storage
    this.fetchSuperAdminProfileDetails(this.admin_userDetails.admin_user_id);
  }

  //dynamically accessing form controls
  get form() {
    return this.validationform.controls;
  }

  //get the super admin info by paasing id and patch the values on form
  fetchSuperAdminProfileDetails(admin_user_id) {
    let response: ApiResponse<SuperAdminInfo>;
    let admin_id = new FormData();
    admin_id.append("user_id", admin_user_id);

    this._adminService.getAdminProfileDetails(admin_id).subscribe(
      (data) => {
        response = data;
      },
      (error) => {
        console.error("error", error);
        this._sharedService.handleError(error);
      },
      () => {
        if (response.success) {
          let admin_profile = response["data"]["super_admin_info"];
          this.gcs_pre_path = response["data"]["gcs_pre_path"];
          this.gcs_pre_path_edit = response["data"]["gcs_pre_path"];

          this.validationform.patchValue({
            first_name: admin_profile.first_name,
            last_name: admin_profile.last_name,
          });
          this.admin_userDetails.admin_first_name = admin_profile.first_name;
          this.admin_userDetails.admin_last_name = admin_profile.last_name;
          this.admin_userDetails.profile_image_path =
            admin_profile.profile_image_path;
          this.fetched_profile_image_path = admin_profile.profile_image_path;
        } else {
          this._sharedService.handleError(response?.error);
        }
      }
    );
  }

  //setting language
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
    window.location.reload();
  }

  toggleMobileMenu(event: any) {
    this.isMenuOpen = !this.isMenuOpen;
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    Swal.fire({
      title: this.areYouSure,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FFB800",
      cancelButtonColor: "#f46a6a ",
      cancelButtonText: this.cancelText,
      confirmButtonText: this.yesLogout,
    }).then((result) => {
      if (result.value) {
        this.authService.setAuthentication(false);
        this.router.navigate(["/account/admin-login"]);
      }
    });
  }

  largeModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: "lg", centered: true });
  }

  validSubmitForm() {
    this.submit = true;
    if (this.validationform.invalid) {
      return;
    } else {
      let response;
      const first_name = this.validationform.value.first_name;
      const last_name = this.validationform.value.last_name;

      let admin_profile_details = new FormData();
      admin_profile_details.append("first_name", first_name);
      admin_profile_details.append("last_name", last_name);

      if (this.adminProfileImageRaw) {
        admin_profile_details.append(
          "profile_image",
          this.adminProfileImageRaw
        );
      }

      admin_profile_details.append(
        "user_id",
        this.admin_userDetails.admin_user_id
      );
      this.buttonLoader = true;
      this._adminService
        .editAdminProfileDetails(admin_profile_details)
        .subscribe(
          (data) => {
            response = data;
            if (response.success) {
              this.toastr.success("Profile updated successfully");
              this.modalService.dismissAll();
              this.fetchSuperAdminProfileDetails(
                this.admin_userDetails.admin_user_id
              );
            } else {
              this._sharedService.handleError(response?.error);
            }
            this.buttonLoader = false;
          },
          (error) => {
            console.error("profile error", error);
            this._sharedService.handleError(error);
            this.buttonLoader = false;
          }
        );
    }
  }

  changeProfileImage() {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg,image/jpg,image/svg";

    input.onchange = (e) => {
      let event = e.target as HTMLInputElement;
      if (event.files.length != 0) {
        var reader = new FileReader();
        reader.readAsDataURL(event.files[0]); // read file as data url
        this.adminProfileImageRaw = event.files[0];
        reader.onload = (event) => {
          this.gcs_pre_path = null;
          this.admin_userDetails.profile_image_path = event.target.result;
        };
      }
    };
    input.click();
  }

  onDismissModal() {}
}
