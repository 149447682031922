<div id="layout-wrapper">
  <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
  </app-topbar>
  <app-sidebar [isCondensed]="isCondensed"></app-sidebar>
  <div class="main-content">
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>
